<template>
  <div>
    <button
    v-if="!tournament"
      class="btn btn-primary btn-agregar-participante"
      @click="openModal = true"
    >
      Agregar criador
    </button>
    <span
      v-else
      class=""
      @click="openModal = true"
    >
      <FeatherIcon icon="PlusCircleIcon" class="icono-agregar-participante" />
  </span>

    <!-- MODAL PARA AGREGAR LOS PARTICIPANTES -->
    <b-modal
      @ok.prevent="saveParticipants"
      title="Agregar criadores"
      v-model="openModal"
      ok-title="Guardar"
      cancel-title="Cancelar"
      scrollable
      no-close-on-backdrop
    >
      <!-- <validation-observer tag="b-row" ref="form"> -->
      <b-row>
        <b-col cols="8" md="10">
          <!-- <validation-provider rules="required" v-slot="{ errors, valid }"> -->
          <b-form-group label="Nombre del criador" label-for="name">
            <b-form-input
              placeholder="Nombre del criador"
              v-model.trim="name"
              @input="(val) => (name = name.toUpperCase())"
              :input="debounceInput"
              @keyup.enter="addParticipant()"
            />
            <!-- <span style="color: red">{{ errors[0] }}</span> -->
          </b-form-group>
          <!-- </validation-provider> -->
        </b-col>
        <b-col cols="4" md="2" class="margin-btn-add">
          <b-button
            type="button"
            variant="primary"
            @click="addParticipant()"
            :disabled="name.length < 4"
            size="sm"
          >
            <feather-icon
              class="icon"
              icon="PlusIcon"
              :size="width < 600 ? '16' : '20'"
            />
          </b-button>
        </b-col>
      </b-row>
      <!-- </validation-observer> -->

      <div>
        <h5 v-if="participants.length > 0" class="mb-2">
          Participantes: {{ participants.length }}
        </h5>
        <ul class="grid-participants-add">
          <li
            v-for="(parti, index) in participants"
            :key="`parti-${index}`"
            class="d-flex justify-content-between align-items-center"
          >
            <p class="text-dark font-weight-bold">
              <!-- <span class="text-dark">{{ index + 1 }}.</span>  -->
              {{ parti }}
            </p>
            <feather-icon
              class="icon text-danger cursor-pointer"
              icon="XCircleIcon"
              :size="width < 600 ? '16' : '20'"
              @click="deleteParticipant(index)"
            />
          </li>
        </ul>
      </div>
    </b-modal>
  </div>
</template>

<script>
import participantService from "../participants.service";
import { mapState } from "vuex";
import * as _ from "lodash";
export default {
  name: "AddParticipant",
  props: {
    tournament: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      openModal: false,
      name: "",
      errorText: false,
      valid: false,
      width: this.$store.state["app"].windowWidth,
      participants: [],
    };
  },
  computed: {},
  methods: {
    async saveParticipants() {
      let count = 0;
      for await (const parti of this.participants) {
        try {
          const { ok, msg } = (
            await participantService.saveParticipant({
              name: parti,
              id_user: this.currentUser.user_id,
              type_id: 2
            })
          ).data;
          count++;

          if (this.participants.length == count) {
            this.openModal = false;
            await this.showSuccessToast(
              "Los participantes fueron agregados con éxito"
            );
            this.$emit("refresh");
            this.participants = [];
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    debounceInput: _.debounce(function (e) {
      this.name = e.target.value;
    }, 500),
    async addParticipant() {
      if (this.name.length < 4) return;
      let exists = this.participants.find((p) => p == this.name);
      console.log(exists);
      if (exists) {
        this.showInfoSwal({
          title: "El participante ya fue ingresado",
          text: "Por favor ingrese otro",
        });
        return;
      } else {
        const { ok, msg } = (
          await participantService.validatesSaveParticipant({ name: this.name, type_id: 2 })
        ).data;
        if (!ok) {
          this.showInfoSwal({
            title: "El participante ya fue registrado",
            text: "Por favor ingrese otro",
          });
          return;
        }
        this.participants.unshift(this.name);
        this.name = "";
      }
    },
    deleteParticipant(index) {
      this.$delete(this.participants, index);
    },
  },
  watch: {
    valid() {
      if (this.name.length > 3) {
        this.valid = true;
      } else {
        this.valid - false;
      }
    },
  },
};
</script>

<style lang="scss" scope>
// .btn-agregar-participante {
//   width: 150px !important;
//   font-size: 10px;
//   @media screen and (min-width: 600px) {
//     width: 200px !important;
//     font-size: 14px;
//   }
// }
.error-input {
  margin-bottom: 0;
  color: red;
  font-size: 12px;
  margin-top: 2px;
}
// h6 {
//   font-weight: normal;
//   font-size: 12px;
// }
.margin-btn-add {
  margin-top: 1.5rem !important;
}
.icono-agregar-participante{
  cursor: pointer;
}
</style>
