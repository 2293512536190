<template>
	<div class="pb-5">
		<div class="mt-2 mb-3 container-header">
			<div class="container-body-header">
				<div class="d-flex align-items-start flex-column">

					<div>
						<p class="mb-50 text-secondary font-small-3">
							Mostrando {{ from }} a {{ to }} de {{ total }} registros
						</p>
					</div>

					<div class="d-flex align-items-center mT-1">
						<p
							class="page-btn"
							:class="[pageActive == 1 ? 'disabled' : null]"
							@click="changePage(pageIn - 1)"
						>
							<feather-icon icon="ChevronLeftIcon" size="20" />
						</p>
						<div class="container-btns">
							<p
								@click="changePage(num)"
								class="page-btn"
								:class="[pageActive == num ? 'active' : null]"
								v-for="(num, index) in pages"
								:key="index"
							>
								{{ num }}
							</p>
						</div>
						<p
							class="page-btn"
							:class="[pageActive == totalPages ? 'disabled' : null]"
							@click="changePage(pageIn + 1)"
						>
							<feather-icon icon="ChevronRightIcon" size="20" />
						</p>
					</div>
					
				</div>

				<div class="d-flex align-items-center justify-content-center mt-2 mt-md-0">
					<b-form-input
						type="text"
						class="form-control"
						placeholder="Buscar criador"
						v-model="search"
						debounce="1000"
						@keyup.enter="searchData()"
					/>
					<div class="d-flex align-items-center justify-content-end ml-2">
						<add-participant @refresh="init" />
					</div>
				</div>
			</div>
		</div>

		<div class="m-auto card content-table">
			<div class="container-table-coliseum header-table">
				<p>#</p>
				<p>Nombre</p>
				<p>
					<span class="mr-1">Ejemplares</span>
					<feather-icon
						class="cursor-pointer"
						icon="ChevronDownIcon"
						size="20"
						@click="changeSpecimensOrder"
						v-if="specimenOrder"
					/>
					<feather-icon
						class="cursor-pointer"
						icon="ChevronUpIcon"
						size="20"
						@click="changeSpecimensOrder"
						v-else
					/>
				</p>
				<p>Creado por</p>
				<p>Opciones</p>
			</div>
			<div class="container-table-coliseum body-table" v-for="(item, index) in data" :key="index">
				<div class="d-flex align-items-center justify-content-center body-index">
					<p>{{ index + 1 }}</p>
				</div>

				<div class="d-flex align-items-center justify-content-center body-cell">
					<p>{{ item.participant_name }}</p>
				</div>

				<div
					class="d-flex align-items-center justify-content-center body-cell"
					:class="[item.counter_specimens ? 'text-primary font-weight-bolder cursor-pointer' : '']"
				>
					<p @click="showListSpecimens(item)">{{ item.counter_specimens || "---" }}</p>
				</div>

				<div class="d-flex flex-column align-items-center justify-content-center body-cell">
					<p>{{ item.created_user.name }}</p>
					<span>{{ item.created_user.date | myGlobalDay }}</span>
				</div>

				<div class="d-flex align-items-center justify-content-center body-cell">
					<edit-participant :participant="item" @refresh="init" />
					<feather-icon
						@click="deleteParticipant(item.id)"
						class="icon text-danger"
						icon="TrashIcon"
						:size="width < 600 ? '18' : '24'"
					/>
				</div>
			</div>
		</div>
		<b-modal
			centered
			:title="breederSpecimensModal.title"
			hide-footer
			v-model="breederSpecimensModal.show"
			@hidden="clearListModal"
		>
			<b-overlay :show="breederSpecimensModal.loading">
				<div class="d-flex justify-content-end">
					<p class="badge badge-primary">Ejemplares: {{ breederSpecimensModal.totalSpecimens }}</p>
				</div>
				<b-input-group class="mb-2">
					<b-form-input
						placeholder="Buscar"
						size="lg"
						v-model="breederSpecimensModal.search"
						@keypress.enter="getBreederSpecimens"
					/>
					<b-input-group-append>
						<b-button class="btn-icon" variant="info" size="lg" @click="getBreederSpecimens">
							<feather-icon icon="SearchIcon" />
						</b-button>
					</b-input-group-append>
				</b-input-group>
				<div>
					<p class="mb-50 text-secondary font-small-3">
						Mostrando {{ breederSpecimensModal.from }} a {{ breederSpecimensModal.to }} de
						{{ breederSpecimensModal.total }} registros
					</p>
				</div>
				<b-pagination
					pills
					hide-goto-end-buttons
					hide-ellipsis
					per-page="50"
					:total-rows="breederSpecimensModal.pagination.total"
					v-model="breederSpecimensModal.pagination.page"
					@change=";(breederSpecimensModal.pagination.page = $event), getBreederSpecimens()"
				></b-pagination>
				<b-row style="max-height: 20rem; overflow: auto">
					<b-col class="mb-2" cols="6" v-for="specimen in breederSpecimensModal.specimens" :key="specimen.id">
						<SpecimenPlate :specimen="specimen" />
					</b-col>
					<b-col cols="12" v-if="breederSpecimensModal.specimens.length == 0">
						<p class="mb-0 text-center font-weight-bold">Sin registros encontrados</p>
					</b-col>
				</b-row>
			</b-overlay>
		</b-modal>
	</div>
</template>

<script>
import participantService from "./participants.service"
import vSelect from "vue-select"
import AddParticipant from "./components/AddParticipant.vue"
import EditParticipant from "./components/EditParticipant.vue"
import SpecimenPlate from "@/components/specimens/SpecimenPlate.vue"
import * as _ from "lodash"
import { CoLgtm } from "oh-vue-icons/icons"

export default {
	name: "ListParticipantsBrain",
	components: { vSelect, AddParticipant, EditParticipant, SpecimenPlate },
	data() {
		return {
			data: [],
			width: this.$store.state["app"].windowWidth,
			pageIn: 1,
			perPageIn: 50,
			search: null, // input search
			pages: [],
			pageActive: 1,
			totalPages: null,
			openModal: false,
			specimenOrder: false,
			breederSpecimensModal: {
				show: false,
				loading: false,
				title: "",
				search: "",
				specimens: [],
				breeder: {},
				pagination: {
					page: 1,
					total: 0,
				},
				to: 0,
				from: 0,
				total: 0,
				totalSpecimens: 0,
			},
			to: 0,
			from: 0,
			total: 0,
		}
	},
	computed: {},
	mounted() {
		this.init()
	},
	methods: {
		async init() {
			this.isPreloading(true)
			const {
				data: { data, last_page, current_page, to, from, total },
			} = await participantService.getContactsToStatus({
				status: 0,
				search: this.search,
				pageIn: this.pageIn,
				perPageIn: this.perPageIn,
				type_id: 2,
				orderby: "counter_specimens",
				order: this.specimenOrder ? "desc" : "asc",
			})
			this.pages = this.createPages(last_page)
			this.pageActive = current_page
			this.totalPages = last_page
			this.data = data.map((item) => {
				return {
					...item,
					created_user: JSON.parse(item.created_user),
				}
			})
			this.to = to || 0
			this.from = from || 0
			this.total = total || 0
			this.isPreloading(false)
		},
		searchData() {
			console.log("hola")
			//   _.debounce(function (e) {
			this.pageIn = 1
			this.init()
			//   }, 500);
		},
		createPages(num) {
			let pages = []
			for (let i = 1; i <= num; i++) {
				pages.push(i)
			}
			return pages
		},
		changePage(page) {
			if (page >= 1 && page <= this.totalPages) {
				this.pageIn = page
				this.init()
			}
		},
		async toggleStatus(id_participant, status) {
			const resp = await participantService.updateToggleStatus({
				status,
				id: id_participant,
			})
		},
		async deleteParticipant(id) {
			const swal = await this.showConfirmSwal()
			if (swal.isConfirmed) {
				const resp = await participantService.deleteParticipantById({
					user_id: this.currentUser.user_id,
					id,
					type: 2,
				})
				this.data = this.data.filter((participant) => id !== participant.id)
			}
		},
		changeSpecimensOrder() {
			this.specimenOrder = !this.specimenOrder
			this.init()
		},
		showListSpecimens(breeder) {
			if (!breeder.counter_specimens) return
			this.breederSpecimensModal.breeder = breeder
			this.breederSpecimensModal.title = `Ejemplares / ${breeder.participant_name}`
			this.breederSpecimensModal.show = !this.breederSpecimensModal.show
			this.breederSpecimensModal.totalSpecimens = breeder.counter_specimens
			this.getBreederSpecimens()
		},
		async getBreederSpecimens() {
			this.breederSpecimensModal.loading = true
			const res = await participantService.getBreederSpecimens({
				breeder_id: this.breederSpecimensModal.breeder.id,
				page: this.breederSpecimensModal.pagination.page,
				perpage: 50,
				search: this.breederSpecimensModal.search,
			})
			this.breederSpecimensModal.specimens = res.data
			this.breederSpecimensModal.pagination.total = res.total
			this.breederSpecimensModal.loading = false
			this.breederSpecimensModal.from = res.from || 0
			this.breederSpecimensModal.to = res.to || 0
			this.breederSpecimensModal.total = res.total || 0
		},
		clearListModal() {
			this.breederSpecimensModal = {
				show: false,
				loading: false,
				title: "",
				search: "",
				specimens: [],
				breeder: {},
				pagination: {
					page: 1,
					total: 0,
				},
			}
		},
	},
	watch: {
		search(newValue, oldValue) {
			this.searchData()
		},
	},
}
</script>

<style lang="scss" scoped>
.container-header {
	background: #fff;
	padding: 1rem;
	border-radius: 10px;
	box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
	transition: 0.1s all ease-in-out;
	@media screen and (min-width: 700px) {
		padding: 1rem;
	}
	.page-btn {
		height: 32px;
		width: 32px;
		margin: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: 0.3s all ease-in-out;
		border-radius: 32px;
		cursor: pointer;
		background: #f3f2f7;
	}
	.container-btns {
		margin: 0 0.4rem;
		width: auto;
		background: #f3f2f7;
		height: 32px;
		border-radius: 16px;
		display: flex;
		align-items: center;
		transition: 0.1s all ease-in-out;
		.active {
			background: #7367f0;
			color: #fff;
		}
	}
	.disabled {
		color: #d5d5d5;
	}
}
.dark-layout {
	.container-header {
		background: #283046;
		.page-btn {
			background: #242b3d;
		}
		.container-btns {
			background: #242b3d;
			.active {
				background: #7367f0;
				color: #fff;
			}
		}
	}
}
.content-table {
	min-width: 100%;
	background: #fff;

	border-radius: 10px;
	overflow-x: scroll;
	overflow-y: visible;
	@media screen and (min-width: 1000px) {
		overflow-x: visible;
		overflow-y: visible;
	}
	.container-table-coliseum {
		min-width: 800px;
		padding: 0.5rem 1rem;
		display: grid;
		grid-template-columns: 1fr 4fr 4fr 4fr 4fr;
		text-align: center;
		@media screen and (min-width: 600px) {
			padding: 1rem 2rem;
		}

		p {
			margin: 0;
			font-size: 12px;
			transition: 0.1s all ease-in-out;
			@media screen and (min-width: 600px) {
				font-size: 14px;
			}
		}
		.icon {
			//color: red;
			margin: 0 0.2rem;
			cursor: pointer;
		}
	}
	.header-table {
		transition: 0.1s all ease-in-out;
		background: #f3f2f7;
		text-align: center;
		p {
			font-weight: 600;
		}
	}
	.body-table {
		border-bottom: 0.5px solid #e6e6e6;
		transition: 0.1s all ease-in-out;
		&:last-child {
			border-bottom: none;
		}
		.body-index {
			min-width: 30.88px;
		}
		.body-cell {
			min-width: 123.52px;
			p {
				word-wrap: break-word;
			}
			.badge {
				width: 80%;
				@media screen and (min-width: 600px) {
					width: 50% !important;
				}
			}
		}
	}
}
.dark-layout {
	.content-table {
		background: #283046;
		.header-table {
			background: #343d55;
		}
		.body-table {
			border-bottom-color: #3b4253;
		}
	}
}
.btn-agregar-organizer {
	width: 180px;
}
.circle {
	height: 6px;
	width: 6px;
	border-radius: 50%;
	margin-right: 4px;
}
.container-body-header {
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 700px) {
		flex-direction: row;
		justify-content: space-between;
	}
}
</style>
