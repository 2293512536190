<template>
	<div>
		<feather-icon
			class="icon cursor-pointer"
			icon="EditIcon"
			:size="width < 600 ? '18' : '24'"
			@click="openModal = true"
		/>

		<b-modal @ok.prevent="saveColiseum" title="Agregar participante" v-model="openModal">
			<validation-observer tag="b-row" ref="form">
				<b-col cols="12">
					<validation-provider rules="required" v-slot="{ errors, valid }">
						<b-form-group label="Nombre del participante" label-for="name">
							<b-form-input
								placeholder="Nombre del participante"
								v-model="name"
								:state="errors[0] ? false : valid ? true : null"
								@input="(val) => (name = name.toUpperCase())"
							/>
							<span style="color: red">{{ errors[0] }}</span>
						</b-form-group>
					</validation-provider>
				</b-col>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import participantService from "../participants.service";
import { mapState } from "vuex";

export default {
	name: "EditParticipant",
	props: ["participant"],
	data() {
		return {
			openModal: false,
			name: "",
			errorText: false,
			valid: false,
			width: this.$store.state["app"].windowWidth,
		};
	},
	computed: {
		...mapState("auth", ["currentUser"]),
	},
	mounted() {
		this.name = this.participant.participant_name;
	},
	methods: {
		async saveColiseum() {
			const success = await this.$refs.form.validate();
			if (success) {
				const {
					data: { ok, msg },
				} = await participantService.updateParticipantById({
					name: this.name,
					id: this.participant.id,
				});

				if (ok) {
					await this.showSuccessToast(msg, "success", "top-left", "Participante actualizado.", "CheckIcon");
					this.openModal = false;
					// this.name = null;
				} else {
					await this.showSuccessToast(msg, "warning    ", "top-left", "Información", "InfoIcon");
				}
				this.$emit("refresh");
			} else {
			}
		},
	},
	watch: {
		valid() {
			if (this.name.length > 3) {
				this.valid = true;
			} else {
				this.valid - false;
			}
		},
		"participant.id"() {
			this.name = this.participant.participant_name;
		},
	},
};
</script>

<style lang="scss" scope>
.btn-agregar-participante {
	width: 200px;
}
.error-input {
	margin-bottom: 0;
	color: red;
	font-size: 12px;
	margin-top: 2px;
}
</style>
