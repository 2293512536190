import axios from "@/axios"

class ParticipantsServiceBrain {
	async getContactsToStatus(body) {
		return await axios.post(`/api/participants/get-toggle`, body)
	}
	async updateToggleStatus(body) {
		return await axios.put("/api/participants/toggle-status", body)
	}
	async validatesSaveParticipant(body) {
		return await axios.post("/api/participants/validate-save-participant", body)
	}
	async saveParticipant(body) {
		return await axios.post("/api/participants/save-participant", body)
	}
	async deleteParticipantById(body) {
		return await axios.put("/api/participants/delete-participant", body)
	}
	async updateParticipantById(body) {
		return await axios.put("/api/participants/update-participant", body)
	}
	async getBreederSpecimens({ breeder_id, search, page, perpage }) {
		const { data } = await axios.get(`/api/participants/get-specimens-by-breeder/${breeder_id}`, {
			params: { search, page, perpage },
		})
		return data
	}
}
export default new ParticipantsServiceBrain()
